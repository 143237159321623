import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // namespaced: true,
  state: {
    navList: [],
    footer: null,
    token: ''
  },
  getters: {
    getNavList(state) {
      return state.navList;
    },
    getFooter(state) {
      return state.footer;
    },
    getToken(state) {
      return state.token;
    }
  },
  mutations: {
    setNav(state, newVal) {
      state.navList = newVal;
    },
    setFooter(state, newVal) {
      state.footer = newVal;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    LOGIN_OUT: (state, newVal) => {
      state.token = newVal;
    }
  },
  actions: {
  },
  modules: {
  }
})
