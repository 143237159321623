import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/house',
    name: 'House',
    component: () => import('@/views/house/index.vue'),
    meta: {
      showPage: true//显示隐藏
    },
    children: [{
      path: 'news',
      name: 'News',
      component: () => import('@/views/house/news/index.vue'),
      meta: {
        showPage: false
      },
    },
    ]
  },

  {
    path: '/designer',
    name: 'Designer',
    component: () => import('@/views/designer/index.vue'),
    meta: {
      showPage: true
    },
    children: [{
      path: 'designerApply',
      name: 'DApply',
      component: () => import('@/views/designer/designerApply/index.vue'),
      meta: {
        showPage: false
      },
    }]
  },
  {
    path: '/brand',
    name: 'Brand',
    component: () => import('@/views/brand/index.vue'),
    meta: {
      showPage: true
    },
    children: [{
      path: 'brandApply',
      name: 'BApply',
      component: () => import('@/views/brand/brandApply/index.vue'),
      meta: {
        showPage: false
      },
    }]
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import('@/views/case/index.vue'),
    meta: {
      showPage: true
    },
    children: [{
      path: 'square',
      name: 'Square',
      component: () => import('@/views/case/square/index.vue'),
      meta: {
        showPage: false
      },
    }]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/index.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('@/views/join/index.vue')
  },
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import('@/views/protocol/index.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/privacy/index.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/views/support/index.vue'),
    meta: {
      showPage: true
    },
    children: [{
      path: 'supportDetail',
      name: 'SDetail',
      component: () => import('@/views/support/supportDetail/index.vue'),
      meta: {
        showPage: false
      },
    }]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
