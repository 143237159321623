<template>
  <div id="app">
    <div class="title">
      <div class="logo">
        <img src="@/assets/images/mjwz.png" alt="加载失败" />
      </div>
      <nav>
        <router-link to="/"><span>首页</span></router-link>
        <router-link to="/house"><span>房产楼讯</span></router-link>
        <router-link to="/designer"><span>设计师入驻</span></router-link>
        <router-link to="/brand"><span>品牌入驻</span></router-link>
        <router-link to="/case"><span>案例市场</span></router-link>
        <router-link to="/support"><span>服务与支持</span></router-link>
        <router-link to="/contact"><span>联系我们</span></router-link>
      </nav>
    </div>
    <div class="kefu" @click="kefu">
      <img src="@/assets/images/avatar.png" alt="加载失败" />
    </div>
    <router-view />
    <footer class="footer">
      <nav>
        <router-link to="/"><span>关于我们</span></router-link
        >| <router-link to="/"><span>加入我们</span></router-link
        >| <router-link to="/contact"><span>联系我们</span></router-link
        >| <router-link to="/protocol"><span>用户协议</span></router-link
        >|
        <router-link to="/privacy"><span>隐私声明</span></router-link>
      </nav>
      <div class="copyright">
        Copyright 2013-现在 美家我住 沪ICP备2021038012号-1
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.storeNav();
    this.storeFooter();
  },
  methods: {
    storeNav() {
      let nav = document.querySelectorAll("a");
      this.$store.commit("setNav", nav);
    },
    storeFooter() {
      let footer = document.querySelector("footer");
      this.$store.commit("setFooter", footer);
    },
    kefu() {
      window.open("https://work.weixin.qq.com/kfid/kfc8d2347c26494460a");
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// chrome、IE/Edge隐藏滚动条
body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

//火狐
html {
  overflow: -moz-hidden-unscrollable;
  height: 100%;
}

body {
  -ms-overflow-style: none;
  height: 100%;
  width: calc(100vw + 18px);
  overflow: auto;
}

.title {
  display: flex;
  // background-color: #2c3e50;
  height: 90px;
  width: 100vw;
  position: absolute;
  top: 3%;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 1px 2px #d5d5d5;
}

.logo {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 55%;
    width: 35%;
  }
}

.kefu {
  position: fixed;
  bottom: 14%;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  img {
    height: 55%;
    width: 35%;
  }
}
nav {
  padding: 30px;
  width: 60%;
  text-align: right;
  display: flex;
  justify-content: space-around;
  z-index: 99999;

  a {
    width: 500px;
    font-size: 17px;
    color: #000000;
    font-weight: regular;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    span {
      line-height: 3;
    }

    &.router-link-exact-active {
      color: #03e289;
      text-decoration: none;

      span {
        border-bottom: 2px solid #03e289;
      }
    }
  }
}

.footer {
  background-color: #000000;
  color: #ffffff;
  height: auto;
  padding-bottom: 15px;
  position: relative;
  bottom: 0;

  nav {
    width: 30%;
    height: 75px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: inherit;

    a {
      color: inherit;
      width: auto;

      &.router-link-exact-active {
        color: #d5d5d5;

        span {
          border-bottom: none;
        }
      }
    }
  }

  .copyright {
    margin-bottom: 5px;
    color: inherit;
  }
}
</style>
