import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css
import "@/assets/iconfont/iconfont";
import "@/assets/iconfont/iconfont.css";
import Config from "./utils/config";
import waterfall from "vue-waterfall2";

Vue.use(waterfall);
Vue.use(ElementUI);
Vue.prototype.$config = Config;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
