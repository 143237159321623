//根路径的处理
const VUE_APP_API_URL = "https://api.meijiawozhu.com";
const VUE_WEB_API_URL = "https://admin.api.meijiawozhu.com";
const TOKENNAME = 'Authori-zation';

const Config = {
    // 服务器地址
    httpUrl: VUE_WEB_API_URL + '/api/admin/',
    // 接口请求地址
    apiBaseURL: VUE_APP_API_URL + '/api/front/',

    token: TOKENNAME,

    HEADER: {
        'content-type': 'application/json'
    },
    HEADERPARAMS: {
        'content-type': 'application/x-www-form-urlencoded'
    },
}
export default Config